<template>
  <div class="footer">
    <a v-if="!$store.state.isLunchMap" class="left" href="watchout/event_information" target="_blank">{{ $t('links') }}</a>
    <a v-if="!$store.state.isLunchMap" href="watchout/privacy-policy">{{ $t('privacy_policy') }}</a>
    <a v-if="!$store.state.isLunchMap" href="watchout/oferta" target="_blank">{{ $t('offer') }}</a>
    <div v-if="!$store.state.isLunchMap">
      {{ $t('run_bar') }}
      <a href="mailto:contact@pubwatch.pl">
        contact@pubwatch.pl
      </a>
      {{ $t('or_fill') }}
      <a href="https://forms.gle/hgxusLzqZfMVZ7ZK7" target="_blank">
        {{ $t('form') }}
      </a>
    </div>
    <div v-if="$store.state.isLunchMap">
      {{ $t('run_bar_lunch') }}
      <a href="#" @click.prevent="openFacebookApp">FB</a>
        {{ $t('or') }}
      <a href="mailto:contact@lunchmap.pl">
        contact@lunchmap.pl
      </a>
    </div>
    <div>
      {{ $t('place_owner') }}
      <a href="watchout/admin">
        login
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      facebookDeepLink: "fb://page/443625168840899",
      facebookWebLink: "https://www.facebook.com/443625168840899"
    };
  },
  methods: {
    openFacebookApp() {
      // Attempt to open the Facebook app
      window.location.href = this.facebookDeepLink;

      // Fallback to the web version in a new tab after a short delay
      setTimeout(() => {
        window.open(this.facebookWebLink, "_blank");
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
  .footer {
    text-align: right;
    padding: 1 / 3 * $default-gap-size $default-gap-size / 2;
    color: $primary-background-color;
    background-color: $primary-inactive-element-color;
    & > * {
      display: inline-block;
      margin: 1 / 6 * $default-gap-size $default-gap-size / 2;
    }
    a {
      display: inline-flex;
      color: $primary-accents-color;
    }
  }
</style>
